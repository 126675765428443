(function($) {
  $.extend($.summernote.lang, {
    'lv-LV': {
      font: {
        bold: 'Treknraksts',
        italic: 'Kursīvs',
        underline: 'Pasvītrots',
        clear: 'Noņemt formatējumu',
        height: 'Līnijas augstums',
        name: 'Fonts',
        strikethrough: 'Nosvītrots',
        superscript: 'Augšraksts',
        subscript: 'Apakšraksts',
        size: 'Fonta lielums',
      },
      image: {
        image: 'Attēls',
        insert: 'Ievietot attēlu',
        resizeFull: 'Pilns izmērts',
        resizeHalf: 'Samazināt 50%',
        resizeQuarter: 'Samazināt 25%',
        floatLeft: 'Līdzināt pa kreisi',
        floatRight: 'Līdzināt pa labi',
        floatNone: 'Nelīdzināt',
        shapeRounded: 'Forma: apaļām malām',
        shapeCircle: 'Forma: aplis',
        shapeThumbnail: 'Forma: rāmītis',
        shapeNone: 'Forma: orģināla',
        dragImageHere: 'Ievēlciet attēlu šeit',
        dropImage: 'Drop image or Text',
        selectFromFiles: 'Izvēlēties failu',
        maximumFileSize: 'Maksimālais faila izmērs',
        maximumFileSizeError: 'Faila izmērs pārāk liels!',
        url: 'Attēla URL',
        remove: 'Dzēst attēlu',
        original: 'Original',
      },
      video: {
        video: 'Video',
        videoLink: 'Video Link',
        insert: 'Insert Video',
        url: 'Video URL?',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion or Youku)',
      },
      link: {
        link: 'Saite',
        insert: 'Ievietot saiti',
        unlink: 'Noņemt saiti',
        edit: 'Rediģēt',
        textToDisplay: 'Saites saturs',
        url: 'Koks URL adresas yra susietas?',
        openInNewWindow: 'Atvērt jaunā logā',
      },
      table: {
        table: 'Tabula',
        addRowAbove: 'Add row above',
        addRowBelow: 'Add row below',
        addColLeft: 'Add column left',
        addColRight: 'Add column right',
        delRow: 'Delete row',
        delCol: 'Delete column',
        delTable: 'Delete table',
      },
      hr: {
        insert: 'Ievietot līniju',
      },
      style: {
        style: 'Stils',
        p: 'Parasts',
        blockquote: 'Citāts',
        pre: 'Kods',
        h1: 'Virsraksts h1',
        h2: 'Virsraksts h2',
        h3: 'Virsraksts h3',
        h4: 'Virsraksts h4',
        h5: 'Virsraksts h5',
        h6: 'Virsraksts h6',
      },
      lists: {
        unordered: 'Nenumurēts saraksts',
        ordered: 'Numurēts saraksts',
      },
      options: {
        help: 'Palīdzība',
        fullscreen: 'Pa visu ekrānu',
        codeview: 'HTML kods',
      },
      paragraph: {
        paragraph: 'Paragrāfs',
        outdent: 'Samazināt atkāpi',
        indent: 'Palielināt atkāpi',
        left: 'Līdzināt pa kreisi',
        center: 'Centrēt',
        right: 'Līdzināt pa labi',
        justify: 'Līdzināt gar abām malām',
      },
      color: {
        recent: 'Nesen izmantotās',
        more: 'Citas krāsas',
        background: 'Fona krāsa',
        foreground: 'Fonta krāsa',
        transparent: 'Caurspīdīgs',
        setTransparent: 'Iestatīt caurspīdīgumu',
        reset: 'Atjaunot',
        resetToDefault: 'Atjaunot noklusējumu',
      },
      shortcut: {
        shortcuts: 'Saīsnes',
        close: 'Aizvērt',
        textFormatting: 'Teksta formatēšana',
        action: 'Darbība',
        paragraphFormatting: 'Paragrāfa formatēšana',
        documentStyle: 'Dokumenta stils',
        extraKeys: 'Citas taustiņu kombinācijas',
      },
      help: {
        insertParagraph: 'Ievietot Paragrāfu',
        undo: 'Atcelt iepriekšējo darbību',
        redo: 'Atkārtot atcelto darbību',
        tab: 'Atkāpe',
        untab: 'Samazināt atkāpi',
        bold: 'Pārvērst tekstu treknrakstā',
        italic: 'Pārvērst tekstu slīprakstā (kursīvā)',
        underline: 'Pasvītrot tekstu',
        strikethrough: 'Nosvītrot tekstu',
        removeFormat: 'Notīrīt stilu no teksta',
        justifyLeft: 'Līdzīnāt saturu pa kreisi',
        justifyCenter: 'Centrēt saturu',
        justifyRight: 'Līdzīnāt saturu pa labi',
        justifyFull: 'Izlīdzināt saturu gar abām malām',
        insertUnorderedList: 'Ievietot nenumurētu sarakstu',
        insertOrderedList: 'Ievietot numurētu sarakstu',
        outdent: 'Samazināt/noņemt atkāpi paragrāfam',
        indent: 'Uzlikt atkāpi paragrāfam',
        formatPara: 'Mainīt bloka tipu uz (p) Paragrāfu',
        formatH1: 'Mainīt bloka tipu uz virsrakstu H1',
        formatH2: 'Mainīt bloka tipu uz virsrakstu H2',
        formatH3: 'Mainīt bloka tipu uz virsrakstu H3',
        formatH4: 'Mainīt bloka tipu uz virsrakstu H4',
        formatH5: 'Mainīt bloka tipu uz virsrakstu H5',
        formatH6: 'Mainīt bloka tipu uz virsrakstu H6',
        insertHorizontalRule: 'Ievietot horizontālu līniju',
        'linkDialog.show': 'Parādīt saites logu',
      },
      history: {
        undo: 'Atsauks (undo)',
        redo: 'Atkārtot (redo)',
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Select Special characters',
      },
    },
  });
})(jQuery);
